import { GeneralExam as GeneralExamType } from 'models/types';

import { Exam } from './exam.model';

export class GeneralExam extends Exam<GeneralExamType> {
  static from(data: GeneralExamType) {
    return new this(data) as GeneralExam & GeneralExamType;
  }

  override isReviewCompleted() {
    return this.get('caseSummaryReviewStatus') === true;
  }

  override getApplicationResultString(): string | null {
    const isAccepted = this.get('status');
    if (isAccepted === null) {
      return null;
    }
    return isAccepted ? 'Accepted' : 'Not Accepted';
  }
}
