import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { NewsitemEntity } from 'models/types';
import { Observable } from 'rxjs';

import { NewsService } from '../services/news.service';

@Injectable({
  providedIn: 'root',
})
export class NewsResolverService implements Resolve<NewsitemEntity[]> {
  constructor(private newsService: NewsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NewsitemEntity[]> {
    return this.newsService.getNewsItems();
  }
}
