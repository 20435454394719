export enum WorkStatus {
  ToDo = 'To Do',
  InProgress = 'In Progress',
  Finished = 'Finished',
}

export enum ReviewType {
  First = 'first',
  Second = 'second',
  Final = 'final',
  View = 'view',
}

export function getReviewTypeForReviewerNumber(reviewerNumber: number) {
  switch (reviewerNumber) {
    case 1:
      return ReviewType.First;
    case 2:
      return ReviewType.Second;
    default:
      throw new Error(`invalid reviewer number: ${reviewerNumber}`);
  }
}
