import { Component, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DiplomateReevaluationEntity, Enum_Diplomatereevaluation_Result, UploadFileEntity } from 'models/types';
import { Subject, tap } from 'rxjs';
import { DiplomateReevaluationResultFormComponent } from 'src/app/components/diplomate-reevaluation/diplomate-reevaluation-result-form/diplomate-reevaluation-result-form.component';
import { ReviewCardComponent } from 'src/app/components/review/review-card/review-card.component';
import { DiplomateReevaluation } from 'src/app/models/diplomate-reevaluation.model';
import { DiplomateReevaluationService } from 'src/app/services/diplomate-reevaluation.service';
import { MemberService } from 'src/app/services/member.service';
import { getMaxPointsNote, REEVALUATION_POINTS_FIELDS } from 'src/app/utils/diplomate-reevaluation';
import { getFullnameForMember } from 'src/app/utils/member';
import { WorkStatus } from 'src/app/utils/review';

@Component({
  selector: 'app-diplomate-reevaluation-review',
  templateUrl: './diplomate-reevaluation-review.component.html',
  styleUrls: ['./diplomate-reevaluation-review.component.scss'],
})
export class DiplomateReevaluationReviewComponent implements OnDestroy {
  @ViewChildren(ReviewCardComponent)
  reviewCards: QueryList<ReviewCardComponent> | undefined = undefined;

  @ViewChild(DiplomateReevaluationResultFormComponent)
  resultForm!: DiplomateReevaluationResultFormComponent;

  private destroyed$ = new Subject<void>();

  isFinalReviewer = false;

  diplomateReevaluationEntity!: DiplomateReevaluationEntity;
  lastDiplomateReevaluationEntity: DiplomateReevaluationEntity | undefined = undefined;

  loading = false;

  get diplomateReevaluation() {
    return DiplomateReevaluation.from(this.diplomateReevaluationEntity.attributes!);
  }

  get lastDiplomateReevaluation() {
    return this.lastDiplomateReevaluationEntity?.attributes;
  }

  get diplomateEntity() {
    return this.diplomateReevaluation?.diplomate?.data;
  }

  get diplomateFullname() {
    return getFullnameForMember(this.diplomateEntity?.attributes);
  }

  get currentReviewerNumber() {
    if (this.isFinalReviewer) {
      return undefined;
    }

    const reviewerNumber = this.diplomateReevaluation
      .getReviewerNumbers(this.memberService.currentMemberId!)
      .reverse()
      .pop();
    if (reviewerNumber === undefined) {
      throw new Error('member is not a reviewer of certifying exam');
    }
    return reviewerNumber;
  }

  get currentReviewerStatusField() {
    if (this.isFinalReviewer) {
      return 'reviewStatus';
    }
    return `reviewer${this.currentReviewerNumber!}Status`;
  }

  get isReviewCompleted() {
    return this.diplomateReevaluation.getFinalReviewerWorkStatus() === WorkStatus.Finished;
  }

  get isReview1Locked() {
    return (
      this.isReviewCompleted ||
      this.diplomateReevaluation?.reviewer1Status === undefined ||
      (this.diplomateReevaluation?.reviewer1Status ?? false)
    );
  }

  get reviewer1WorkStatus() {
    return this.diplomateReevaluation.getReviewer1WorkStatus();
  }

  get reviewer2WorkStatus() {
    return this.diplomateReevaluation.getReviewer2WorkStatus();
  }

  get isReview2Locked() {
    return (
      this.isReviewCompleted ||
      this.diplomateReevaluation?.reviewer2Status === undefined ||
      (this.diplomateReevaluation?.reviewer2Status ?? false)
    );
  }

  get isReviewer1() {
    return this.currentReviewerNumber == 1;
  }

  get isReviewer2() {
    return this.currentReviewerNumber == 2;
  }

  get isReviewValid() {
    return (
      ((!this.isFinalReviewer || this.resultForm?.isValid) &&
        this.reviewForms?.map((reviewForm) => reviewForm.isValid).every((b) => !!b)) ??
      false
    );
  }

  get reviewForms() {
    return this.reviewCards ? this.reviewCards.map((reviewCard) => reviewCard.reviewForm) : [];
  }

  get reviewFormsEnabled() {
    return this.reviewForms.filter((rfc) => !rfc.disabled);
  }

  get reviewFormsValues() {
    return Object.assign({}, ...this.reviewFormsEnabled.map((rfc) => rfc.values));
  }

  get lastReevaluationYear() {
    const lastReevaluationYear = this.lastDiplomateReevaluation?.reevaluationYear;
    // prevent returning incorrect value for year
    if (lastReevaluationYear === undefined || lastReevaluationYear < 2000) {
      return undefined;
    }
    return lastReevaluationYear;
  }

  get infoSummaryTableData() {
    return [
      { key: 'Diplomate', value: getFullnameForMember(this.diplomateReevaluation?.diplomate?.data?.attributes) },
      { key: 'Specialty', value: this.diplomateReevaluation?.speciality?.Name ?? '' },
      { key: 'Last Re-evaluation', value: this.lastDiplomateReevaluation?.reevaluationYear ?? '-' },
      { key: 'Re-evaluation Period Start', value: this.diplomateReevaluation?.reevaluationPeriodStart ?? '' },
      { key: 'Re-evaluation Period End', value: this.diplomateReevaluation?.reevaluationPeriodEnd ?? '' },
      { key: 'Requested Future Status', value: this.diplomateReevaluation?.requestedDiplomateStatus ?? '' },
    ];
  }

  get ebvsRequirementsTableData() {
    return [
      { key: 'Work Hours per week', value: this.diplomateReevaluation?.workHoursPerWeek ?? '' },
      {
        key: 'Work Types',
        value: this.diplomateReevaluation?.workTypes?.map((workType) => workType?.name).join(', ') ?? '',
      },
      {
        key: 'AGM Attendance Years',
        value:
          this.diplomateReevaluation?.agmAttendanceYears
            ?.map((agmAttendanceYear) => agmAttendanceYear?.year)
            .join(', ') ?? '',
      },
    ];
  }

  get reevaluationPointsTableData() {
    return Object.entries(REEVALUATION_POINTS_FIELDS).map(([pointsField, info]) => ({
      key: `${info.label}${getMaxPointsNote(info.max)}`,
      value: (this.diplomateReevaluation as any)?.[pointsField] ?? 0,
    }));
  }

  get isReviewFormNotAccepted() {
    return this.reviewFormsEnabled.some((reviewForm) => reviewForm.isAccepted === false);
  }

  get resultOptions() {
    return Object.values(Enum_Diplomatereevaluation_Result).filter(
      (result) => !(result === Enum_Diplomatereevaluation_Result.Successful && this.isReviewFormNotAccepted)
    );
  }

  constructor(
    private memberService: MemberService,
    private diplomateReevaluationService: DiplomateReevaluationService,
    private snackbar: MatSnackBar,
    private router: Router
  ) {
    const state = this.router.getCurrentNavigation()?.extras.state;

    if (!state?.['diplomateReevaluation']) {
      router.navigate(['/committee-work/diplomate-re-evaluations-review']);
      return;
    }

    this.diplomateReevaluationEntity = state['diplomateReevaluation'] as DiplomateReevaluationEntity;
    this.isFinalReviewer = (state['isFinalReviewer'] ?? false) as boolean;
    diplomateReevaluationService
      .getLastDiplomateReevaluationForMember(this.diplomateEntity!.id!, this.diplomateReevaluation!.reevaluationYear)
      .pipe(tap((diplomateReevaluation) => (this.lastDiplomateReevaluationEntity = diplomateReevaluation)))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$.unsubscribe();
  }

  convertFileToArray(file: UploadFileEntity | undefined | null) {
    return [file].filter((f) => !!f) as UploadFileEntity[];
  }

  saveReview(lock = false): void {
    if (!this.diplomateReevaluationEntity?.id) {
      return;
    }

    let diplomateReevaluationInput = this.reviewFormsValues;

    if (this.isFinalReviewer) {
      diplomateReevaluationInput = { ...diplomateReevaluationInput, ...this.resultForm.values };
    }

    if (lock) {
      diplomateReevaluationInput[this.currentReviewerStatusField!] = true;
    }

    this.diplomateReevaluationService
      .updateDiplomateReevaluationReview(this.diplomateReevaluationEntity.id, diplomateReevaluationInput)
      .subscribe({
        next: (result) => {
          this.loading = result.loading;
          if (result.data) {
            this.diplomateReevaluationEntity = result.data.updateDiplomateReevaluation.data!;
            this.snackbar.open('Your review data was updated successfully!', undefined, { duration: 4000 });
          }
        },
      });
  }
}
