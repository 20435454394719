import { CertifyingExam as CertifyingExamType } from 'models/types';

import { Exam } from './exam.model';

export class CertifyingExam extends Exam<CertifyingExamType> {
  static from(data: CertifyingExamType) {
    return new this(data) as CertifyingExam & CertifyingExamType;
  }

  override getApplicationResultString(): string | null {
    return this.get('applicationResult');
  }
}
