<app-grid class="p-2" *ngIf="hasRole; else newUserInfo">
  <div class="mat-display-1">Recent news</div>
  <app-grid-row *ngFor="let newsitem of newsItems">
    <app-news-card [newsItem]="newsitem"></app-news-card>
  </app-grid-row>
</app-grid>

<ng-template #newUserInfo>
  <app-grid class="p-2">
    <app-new-user-info></app-new-user-info>
  </app-grid>
</ng-template>
