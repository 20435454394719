import { Component, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import {
  Enum_Componentmasterdataspeciality_Name,
  GeneralExamEntity,
  GeneralExamInput,
  UploadFileEntity,
} from 'models/types';
import { Subject } from 'rxjs';
import { ReviewCardComponent } from 'src/app/components/review/review-card/review-card.component';
import { GeneralExam } from 'src/app/models/general-exam.model';
import { GeneralExamService } from 'src/app/services/general-exam.service';
import { ReviewType } from 'src/app/utils/review';

@Component({
  selector: 'app-general-exam-review',
  templateUrl: './general-exam-review.component.html',
  styleUrls: ['./general-exam-review.component.scss'],
})
export class GeneralExamReviewComponent implements OnDestroy {
  @ViewChildren(ReviewCardComponent)
  reviewCards: QueryList<ReviewCardComponent> | undefined;

  private destroyed$ = new Subject<void>();

  generalExamEntity!: GeneralExamEntity;

  loading = false;

  get generalExam() {
    return GeneralExam.from(this.generalExamEntity.attributes!);
  }

  get reviewType() {
    return this.activatedRoute.snapshot.paramMap.get('type') as ReviewType;
  }

  get isViewer() {
    return this.reviewType === ReviewType.View;
  }

  get isFinalReviewer() {
    return this.reviewType === ReviewType.Final;
  }

  get isReviewer1() {
    return this.reviewType === ReviewType.First;
  }

  get isReviewer2() {
    return this.reviewType === ReviewType.Second;
  }

  get isReview1Visible() {
    return this.isFinalReviewer || this.isReviewer1 || this.isViewer;
  }

  get isReview2Visible() {
    return this.isFinalReviewer || this.isReviewer2 || this.isViewer;
  }

  get isReview2CaseSummaryVisible() {
    const speciality = this.generalExam.speciality?.Name;
    if (!speciality) {
      throw new Error('speciality is not set');
    }
    return (
      this.isReview2Visible &&
      ![
        Enum_Componentmasterdataspeciality_Name.Oncology,
        Enum_Componentmasterdataspeciality_Name.InternalMedicine,
      ].includes(speciality)
    );
  }

  get isFinalReviewVisible() {
    return this.isFinalReviewer || this.isViewer;
  }

  get isReview1Disabled() {
    return this.loading || this.generalExam.isReview1Completed() || this.isFinalReviewer || this.isViewer;
  }

  get isReview2Disabled() {
    return this.loading || this.generalExam.isReview2Completed() || this.isFinalReviewer || this.isViewer;
  }

  get isFinalReviewDisabled() {
    return (
      this.loading ||
      this.generalExam.isReviewCompleted() ||
      !this.generalExam.isReview1Completed() ||
      !this.generalExam.isReview2Completed() ||
      this.isViewer
    );
  }

  get reviewForms() {
    return this.reviewCards ? this.reviewCards.map((reviewCard) => reviewCard.reviewForm) : [];
  }

  get isReviewValid() {
    return this.reviewForms?.map((reviewForm) => reviewForm.isValid).every((b) => !!b) ?? false;
  }

  get reviewFormsValues(): GeneralExamInput {
    return Object.assign({}, ...this.reviewForms.filter((rfc) => !rfc.disabled).map((rfc) => rfc.values));
  }

  get errorMessageRequired() {
    return 'This field is required!';
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private generalExamService: GeneralExamService,
    private snackbar: MatSnackBar
  ) {
    this.generalExamEntity = this.activatedRoute.snapshot.data['generalExam'] as GeneralExamEntity;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$.unsubscribe();
  }

  convertFileToArray(file: UploadFileEntity | undefined | null) {
    return [file].filter((f) => !!f) as UploadFileEntity[];
  }

  saveReview(lock = false): void {
    if (!this.generalExamEntity?.id) {
      return;
    }

    let generalExamInput = this.reviewFormsValues;

    if (lock) {
      if (this.isFinalReviewer) {
        generalExamInput.caseSummaryReviewStatus = true;
      } else {
        // these fields also reference to the review status of the case summary
        const reviewStatusField = this.isReviewer1 ? 'reviewer1Status' : 'reviewer2Status';
        generalExamInput[reviewStatusField] = true;
      }
    }

    this.generalExamService.updateGeneralExamReview(this.generalExamEntity.id, generalExamInput).subscribe({
      next: (result) => {
        this.loading = result.loading;
        if (result.data) {
          this.generalExamEntity = result.data.updateGeneralExam.data!;
          this.snackbar.open('Your review data was updated successfully!', undefined, { duration: 4000 });
        }
      },
    });
  }
}
