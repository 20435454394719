import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthProvider, AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss'],
})
export class AuthCallbackComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService) {
    // pass
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/']);
    } else {
      const provider = this.route.snapshot.paramMap.get('provider') ?? AuthProvider.Cognito;

      if (window.location.search && Object.values(AuthProvider).includes(provider as AuthProvider)) {
        const httpParams = new HttpParams({ fromString: window.location.search });
        this.authService.handleLoginCallback(provider as AuthProvider, httpParams);
        return;
      }
      this.router.navigate(['/login']);
    }
  }
}
