import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Maybe } from 'graphql/jsutils/Maybe';
import { NavigationItem } from 'models/types';
import { defer, map, share } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { MemberService } from 'src/app/services/member.service';
import { getFullnameForMember } from 'src/app/utils/member';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  mobile: boolean = false;

  // TODO do not display menu entries of unpublished pages

  navItems$ = defer(() =>
    this.activatedRoute.data.pipe(
      map((data) => {
        const navItems = data['menu'] as NavigationItem[];
        return this._filterByAudience(navItems);
      })
    )
  );
  hasNavItems$ = this.navItems$.pipe(map((navItems) => !!navItems.length));

  get member() {
    return this.memberService.currentMember;
  }

  get memberName() {
    return getFullnameForMember(this.member, { title: false, middlename: false });
  }

  get memberEmail() {
    return this.member?.email;
  }

  _filterByAudience(items: NavigationItem[] | Maybe<NavigationItem>[]): NavigationItem[] {
    if (!items) {
      return [];
    }
    // filter items
    const filteredItems = items.filter((item) =>
      (item?.audience ?? []).some((a) => this.memberService.audience.includes(a ?? ''))
    ) as NavigationItem[];

    // filter subitems
    return filteredItems.map((item) => ({ ...item, items: this._filterByAudience(item.items ?? []) }));
  }

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private memberService: MemberService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe((result) => {
      this.mobile = result.matches;
    });
  }

  signOut(): void {
    this.authService.logout();
  }
}
