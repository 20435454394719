import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { PaymentEntityResponse, PaymentEntityResponseCollection } from 'models/types';
import { environment } from 'src/environments/environment';

import { AuthService } from './auth.service';

export const PAYMENT_DATA_EXPAND = `
  data {
    id
    attributes {
      type
      paid
      canceled
      subject
      duedate
      paymentdate
      displaytext
      currency
      amount
      totalAmount
      paymentItems {
        description
        amount
      }
      invoicenumber
      invoice {
        data {
          attributes {
            url
          }
        }
      }
    }
  }
`;

const GET_PAYMENTS_FOR_MEMEBER_QUERY = gql`
  query ($memberId: ID!) {
    payments(filters: { payer: { id: { eq: $memberId } } }) {
      ${PAYMENT_DATA_EXPAND}
    }
  }
`;

const GET_PAYMENT_QUERY = gql`
  query ($id: ID!) {
    payment(id: $id) {
      ${PAYMENT_DATA_EXPAND}
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(private apollo: Apollo, private http: HttpClient, private auth: AuthService) {
    // pass
  }

  getPaymentsForMember(memberId: string) {
    return this.apollo.query<{ payments: PaymentEntityResponseCollection }>({
      query: GET_PAYMENTS_FOR_MEMEBER_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        memberId,
      },
    });
  }

  getPayment(id: string) {
    return this.apollo.query<{ payment: PaymentEntityResponse }>({
      query: GET_PAYMENT_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    });
  }

  createPaypalOrder(paymentId: string) {
    const url = `${environment.strapiBackendUrl}/api/payment/paypalCreate/${paymentId}`;
    const { token } = this.auth;
    return this.http.post<{ id: number }>(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  approvePaypalOrder(orderId: string) {
    const url = `${environment.strapiBackendUrl}/api/payment/capture/${orderId}`;
    const { token } = this.auth;
    return this.http.post<{ id: number }>(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
}
