import { CertifyingExam, GeneralExam } from 'models/types';

import { getFullnameForMember } from '../utils/member';
import { Reviewable } from './reviewable.model';

export abstract class Exam<E extends GeneralExam | CertifyingExam> extends Reviewable<E> {
  getResidentFullname() {
    return getFullnameForMember(this.get('resident')?.data?.attributes);
  }

  abstract getApplicationResultString(): string | null;
}
