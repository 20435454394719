import { CertifyingExam, DiplomateReevaluation, GeneralExam, MemberEntity } from 'models/types';

import { WorkStatus } from '../utils/review';
import { Basic } from './basic.model';

export abstract class Reviewable<R extends GeneralExam | CertifyingExam | DiplomateReevaluation> extends Basic<R> {
  getSpecialityString() {
    return this.get('speciality')?.Name?.replace('_', ' ');
  }

  getReviewer1WorkStatus(): WorkStatus {
    return this.getWorkStatusForReviewer(1);
  }

  getReviewer2WorkStatus(): WorkStatus {
    return this.getWorkStatusForReviewer(2);
  }

  getFinalReviewerWorkStatus() {
    if (this.isReviewCompleted()) {
      return WorkStatus.Finished;
    }
    return this.getReviewer1WorkStatus() === WorkStatus.Finished &&
      this.getReviewer2WorkStatus() === WorkStatus.Finished
      ? WorkStatus.ToDo
      : WorkStatus.InProgress;
  }

  isReviewer1(memberId: string) {
    return memberId === this.get('reviewer1')?.data?.id;
  }

  isReviewer2(memberId: string) {
    return memberId === this.get('reviewer2')?.data?.id;
  }

  isReviewCompleted() {
    return this.get('reviewStatus') === true;
  }

  isReview1Completed() {
    return this.getReviewer1WorkStatus() === WorkStatus.Finished;
  }

  isReview2Completed() {
    return this.getReviewer2WorkStatus() === WorkStatus.Finished;
  }

  getReviewerNumbers(memberId: string) {
    return [this.isReviewer1(memberId) ? 1 : [], this.isReviewer2(memberId) ? 2 : []].flat();
  }

  getWorkStatusForReviewer(reviewerNumber: number): WorkStatus {
    if (reviewerNumber < 1 || reviewerNumber > 2) {
      throw new Error(`invalid reviewer number: ${reviewerNumber}`);
    }
    const reviewerStatus = reviewerNumber == 1 ? this.get('reviewer1Status') : this.get('reviewer2Status');
    return this.isReviewCompleted() || reviewerStatus ? WorkStatus.Finished : WorkStatus.ToDo;
  }
}
