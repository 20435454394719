<app-grid class="p-2">
  <div class="login-container">
    <mat-card>
      <div class="title">User Login</div>
      <mat-card-content>
        <p>Please choose one of the following providers:</p>
        <div class="button-group">
          <button id="button-cognito" mat-raised-button color="primary" (click)="login(AuthProvider.Cognito)">
            <mat-icon>login</mat-icon>
            Legacy Login
          </button>
          <button mat-raised-button (click)="login(AuthProvider.Nextcloud)">
            <img
              src="https://nextcloud.com/c/uploads/2022/08/nextcloud-logo-icon.svg"
              alt="Nextcloud Logo"
              title="Nextcloud Logo" />
            Login with Nextcloud
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</app-grid>
