<app-grid class="p-2">
  <mat-card>
    <mat-card-title>General Exam Review</mat-card-title>
    <mat-card-content>
      <ng-container *ngIf="!isViewer">
        <p>Dear Reviewer,</p>
        <p>
          please find all uploaded documents of {{ generalExam.getResidentFullname() }} here below for you to review.<br />
          Please evaluate all items (besides the case reports) either with “adequate” or “inadequate”.<br />
          You can start the review, save it and continue it at a later time by clicking on "save" at the bottom of the
          page. When you are finished with the review and want to submit it, please click on "submit". Please note: once
          you have clicked on submit, the review is no longer accessible to you!<br />
        </p>

        <p>
          The review cannot be submitted if an information is missing. If this is the case, the system will tell you.
        </p>
        <p>
          For credentials requirements, please check the ECVIM-CA IB
          <a routerLink="/ressources/information-brochures" target="_blank"
            >https://memberportal.ecvim-ca.college/ressources/information-brochures</a
          >
          or in case of any uncertainty, please contact your credentials committee chair.
        </p>

        <b>Notes-Box</b>
        <p>The “Notes” box is for your notes only, which only you and the Committee Chair can see.</p>

        <b>Comments-Box</b>
        <p>
          If you rate an item “inadequate” a “Comments” box will open. Here you should provide constructive feedback to
          the Resident. Please note that this feedback will be included in the feedback letter for the Resident.
        </p>
      </ng-container>

      <app-general-exam-application-details [generalExam]="generalExam"></app-general-exam-application-details>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="generalExam.caseSummary?.data?.length">
    <mat-card-title>Case Summary</mat-card-title>

    <mat-card-content>
      <p>
        Internal Medicine and Cardiology Residents must submit one case summary at the time of application for the
        General Examination.
      </p>
      <p>
        Oncology Residents may submit either their first or second case summary, or both, at the time of application for
        the General Examination, as appropriate for their stage of residency.
      </p>
      <p>
        Detailed instructions on the format and the requirements of the case summaries can be found in the respective
        Information Brochure.
      </p>
      <app-review-file-upload [files]="generalExam.caseSummary?.data ?? []"></app-review-file-upload>
      <div class="flex-container">
        <app-review-card
          class="flex-item"
          *ngIf="isReview1Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="generalExam.getReviewer1WorkStatus()"
          title="Reviewer 1"
          [disabled]="isReview1Disabled"
          field="caseSummaryReviewer1"
          [comment]="this.generalExam.caseSummaryReviewer1Comment"
          [notes]="this.generalExam.caseSummaryReviewer1Notes"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isReview2CaseSummaryVisible"
          [showHeader]="isFinalReviewer"
          [workStatus]="generalExam.getReviewer2WorkStatus()"
          title="Reviewer 2"
          [disabled]="isReview2Disabled"
          field="caseSummaryReviewer2"
          [comment]="this.generalExam.caseSummaryReviewer2Comment"
          [notes]="this.generalExam.caseSummaryReviewer2Notes"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewVisible"
          [showHeader]="true"
          title="Final Review"
          [disabled]="isFinalReviewDisabled"
          field="caseSummary"
          [comment]="this.generalExam.caseSummaryComment"></app-review-card>
      </div>
    </mat-card-content>
  </mat-card>

  <!-- BUTTONS AND RESULT -->

  <mat-card>
    <div *ngIf="isFinalReviewVisible">
      <app-review-file-upload
        [files]="convertFileToArray(generalExam.caseSummaryConclusionLetter?.data)"></app-review-file-upload>
    </div>

    <button
      mat-raised-button
      (click)="saveReview()"
      [disabled]="
        loading ||
        generalExam.isReviewCompleted() ||
        (isReviewer1 && isReview1Disabled) ||
        (isReviewer2 && isReview2Disabled) ||
        (isFinalReviewer && isFinalReviewDisabled)
      ">
      Save <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
    </button>

    <button
      mat-raised-button
      (click)="saveReview(true)"
      [disabled]="
        loading ||
        !isReviewValid ||
        generalExam.isReviewCompleted() ||
        (isReviewer1 && isReview1Disabled) ||
        (isReviewer2 && isReview2Disabled) ||
        (isFinalReviewer && isFinalReviewDisabled)
      ">
      Submit <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
    </button>
  </mat-card>
</app-grid>
