import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { NewsitemEntityResponseCollection } from 'models/types';
import { map } from 'rxjs';

const NEWSITEMS = gql`
  query {
    newsitems {
      data {
        id
        attributes {
          Content
          Title
          publishedAt
          Picture {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private apollo: Apollo) {}

  getNewsItems() {
    return this.apollo
      .query<{ newsitems: NewsitemEntityResponseCollection }>({
        query: NEWSITEMS,
      })
      .pipe(
        map((result) => result.data.newsitems.data ?? []),
        map((newsItems) =>
          [...newsItems]
            .sort((n1, n2) => {
              return n1.attributes?.publishedAt.localeCompare(n2.attributes?.publishedAt);
            })
            .reverse()
        )
      );
  }
}
