import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { NewsitemEntity } from 'models/types';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss'],
})
export class NewsCardComponent implements OnInit {
  @Input()
  newsItem!: NewsitemEntity;

  isMobile = false;

  get images() {
    return this.newsItem.attributes?.Picture?.data ?? [];
  }

  get title() {
    return this.newsItem.attributes?.Title ?? '';
  }

  get content() {
    return this.newsItem.attributes?.Content ?? '';
  }

  get publishedAt() {
    return this.newsItem.attributes?.publishedAt;
  }

  constructor(private breakpointObserver: BreakpointObserver) {
    console.log('stop');
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe((result) => {
      this.isMobile = result.matches;
    });
  }
}
