import { Component, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Member, MemberInput } from 'models/types';
import { MemberFormComponent } from 'src/app/components/member-form/member-form.component';
import { MemberService } from 'src/app/services/member.service';

import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  @ViewChild(MemberFormComponent)
  memberForm: MemberFormComponent | null = null;

  // form is disabled by default
  formDisabled = true;

  loading = false;

  get member() {
    return this.memberService.currentMember as Partial<Member>;
  }

  constructor(
    private memberService: MemberService,
    private profileService: ProfileService,
    private snackbar: MatSnackBar
  ) {
    // pass
  }

  enableEditMode(): void {
    this.formDisabled = false;
    this.memberForm?.memberForm.markAllAsTouched();
  }

  save(): void {
    if (!this.memberForm!.memberFormInvalid) {
      this.profileService
        .updateMember(this.memberService.currentMemberId!, this.memberForm?.memberFormValues as MemberInput)
        .subscribe({
          next: (result) => {
            this.loading = result.loading;
            if (result.data) {
              this.memberService.setMember(result.data.updateMember.data!);
              this.snackbar.open('Your profile was updated successfully!', undefined, { duration: 4000 });
            }
          },
        });
    }
  }
}
