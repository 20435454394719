<mat-card [ngClass]="{ mobile: isMobile }">
  <mat-card-title>{{ title }}</mat-card-title>
  <mat-card-subtitle>{{ publishedAt | date }}</mat-card-subtitle>

  <div class="container">
    <div *ngIf="images.length" class="image-container">
      <div *ngFor="let image of images">
        <img [src]="image.attributes?.url" [alt]="title" />
      </div>
    </div>

    <mat-card-content class="news-text" markdown ngPreserveWhitespaces>
      {{ content }}
    </mat-card-content>
  </div>
</mat-card>
