import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DiplomateReevaluation as DiplomateReevaluationType, DiplomateReevaluationEntity } from 'models/types';
import { Subject, takeUntil, tap } from 'rxjs';
import { DiplomateReevaluation } from 'src/app/models/diplomate-reevaluation.model';
import { MemberService } from 'src/app/services/member.service';
import { getFullnameForMember } from 'src/app/utils/member';
import { WorkStatus } from 'src/app/utils/review';

interface Letter {
  name: string;
  link: string;
}

interface TableDataItem {
  reviewType: string;
  diplomate: string;
  reviewer1: string;
  reviewer2: string;
  reviewStatus: WorkStatus;
  result: string | null;
  resultLetters: Letter[];
  routerLink: string;
  state: any;
}

@Component({
  selector: 'app-diplomate-reevaluation-review-overview',
  templateUrl: './diplomate-reevaluation-review-overview.component.html',
  styleUrls: ['./diplomate-reevaluation-review-overview.component.scss'],
})
export class DiplomateReevaluationReviewOverviewComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  diplomateReevaluationsAsReviewer: DiplomateReevaluationEntity[] = [];
  diplomateReevaluationsAsReevaluationsChair: DiplomateReevaluationEntity[] = [];

  workStatusEnum = WorkStatus; // Expose enum in component class

  diplomateReevaluationsForReviewTableDisplayedColumns: string[] = [
    'reviewType',
    'diplomate',
    'reviewers',
    'reviewStatus',
    'result',
    'resultLetter',
  ];
  diplomateReevaluationsForReviewTableData: TableDataItem[] = [];

  constructor(private activatedRoute: ActivatedRoute, private memberService: MemberService) {
    this.activatedRoute.data
      .pipe(
        takeUntil(this.destroyed$),
        tap((data) => {
          this.diplomateReevaluationsAsReviewer = (data['diplomateReevaluationsAsReviewer'] ??
            []) as DiplomateReevaluationEntity[];
          this.diplomateReevaluationsAsReevaluationsChair = (data['diplomateReevaluationsAsReevaluationsChair'] ??
            []) as DiplomateReevaluationEntity[];
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.diplomateReevaluationsForReviewTableData = [
      ...this.diplomateReevaluationsAsReviewer.map((dre) => this.getTableDataFromDiplomateReevaluationAsReviewer(dre)),
      ...this.diplomateReevaluationsAsReevaluationsChair.map((dre) =>
        this.getTableDataFromDiplomateReevaluationAsReevaluationsChair(dre)
      ),
    ];
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$.unsubscribe();
  }

  getTableDataFromDiplomateReevaluation(diplomateReevaluationEntity: DiplomateReevaluationEntity) {
    return {
      diplomate: getFullnameForMember(diplomateReevaluationEntity.attributes?.diplomate?.data?.attributes),
      reviewer1: getFullnameForMember(diplomateReevaluationEntity.attributes?.reviewer1?.data?.attributes),
      reviewer2: getFullnameForMember(diplomateReevaluationEntity.attributes?.reviewer2?.data?.attributes),
      resultLetters: this.getDiplomateReevaluationResultLetters(diplomateReevaluationEntity.attributes),
      routerLink: '/committee-work/review-diplomate-re-evaluation',
      state: { diplomateReevaluation: diplomateReevaluationEntity },
      result: this.getDiplomateReevaluationResult(diplomateReevaluationEntity.attributes) ?? null,
    };
  }

  getTableDataFromDiplomateReevaluationAsReviewer(
    diplomateReevaluationEntity: DiplomateReevaluationEntity
  ): TableDataItem {
    const diplomateReevaluation = DiplomateReevaluation.from(diplomateReevaluationEntity.attributes!);
    const reviewerNumber = diplomateReevaluation.getReviewerNumbers(this.memberService.currentMemberId!)[0];
    return {
      ...this.getTableDataFromDiplomateReevaluation(diplomateReevaluationEntity),
      reviewType: 'Committee',
      reviewStatus: diplomateReevaluation.getWorkStatusForReviewer(reviewerNumber),
    };
  }

  getTableDataFromDiplomateReevaluationAsReevaluationsChair(
    diplomateReevaluationEntity: DiplomateReevaluationEntity
  ): TableDataItem {
    const tableData = this.getTableDataFromDiplomateReevaluation(diplomateReevaluationEntity);
    const diplomateReevaluation = DiplomateReevaluation.from(diplomateReevaluationEntity.attributes!);
    return {
      ...tableData,
      reviewType: 'Chair',
      reviewStatus: diplomateReevaluation.getFinalReviewerWorkStatus(),
      state: { ...tableData.state, isFinalReviewer: true },
    };
  }

  getDiplomateReevaluationResultLetters(diplomateReevaluation: DiplomateReevaluationType | undefined | null) {
    return (
      diplomateReevaluation?.resultLetters?.data.map((resultLetter) => ({
        name: 'Result Letter',
        link: resultLetter.attributes?.url ?? '',
      })) ?? []
    );
  }

  getDiplomateReevaluationResult(diplomateReevaluation: DiplomateReevaluationType | undefined | null) {
    if (!diplomateReevaluation) {
      return null;
    }
    return diplomateReevaluation.result ?? null;
  }
}
