<app-grid class="p-2">
  <mat-card>
    <mat-card-title>Certifying Exam Review</mat-card-title>
    <mat-card-content>
      <ng-container *ngIf="!isViewer">
        <p>Dear Reviewer,</p>
        <p>
          please find all uploaded documents of {{ certifyingExam.getResidentFullname() }} here below for you to
          review.<br />
          Please evaluate all items (besides the case reports) either with “adequate” or “inadequate”.<br />
          You can start the review, save it and continue it at a later time by clicking on "save" at the bottom of the
          page. When you are finished with the review and want to submit it, please click on "submit". Please note: once
          you have clicked on submit, the review is no longer accessible to you!<br />
        </p>
        <p>
          The review cannot be submitted if an information is missing. If this is the case, the system will tell you.
        </p>
        <p>
          For credentials requirements, please check the ECVIM-CA IB
          <a routerLink="/ressources/information-brochures" target="_blank"
            >https://memberportal.ecvim-ca.college/ressources/information-brochures</a
          >
          or in case of any uncertainty, please contact your credentials committee chair.
        </p>

        <b>Notes-Box</b>
        <p>The “Notes” box is for your notes only, which only you and the Committee Chair can see.</p>

        <b>Comments-Box</b>
        <p>
          If you rate an item “inadequate” a “Comments” box will open. Here you should provide constructive feedback to
          the Resident. Please note that this feedback will be included in the feedback letter for the Resident.
        </p>
      </ng-container>

      <app-certifying-exam-application-details
        [certifyingExam]="certifyingExam"
        [previousCertifyingExamEntities]="
          previousCertifyingExamEntities$ | async
        "></app-certifying-exam-application-details>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="certifyingExam.geResult?.data || isSubmitLaterSelected('geResult')">
    <mat-card-header>
      <mat-card-title>Proof of passing the General Examination</mat-card-title>
      <app-required-note [status]="isCredentialRequired('geResult')"></app-required-note>
    </mat-card-header>

    <mat-card-content>
      <p>
        If the candidate has previously passed the General Examination, he/she must supply proof of passing the
        examination (General Examination result letter) as well as the information listed below. If he/she wishes to
        take both examinations at the same time he/she will be required to submit an online application for General
        Examination as well as for the Certifying Examination. The Resident is required to upload a note that he/she is
        intending to sit both examinations in the same year.
      </p>

      <app-submit-later-alert *ngIf="isSubmitLaterSelected('geResult')"></app-submit-later-alert>

      <app-review-file-upload [files]="convertFileToArray(certifyingExam.geResult?.data)"></app-review-file-upload>

      <div class="flex-container">
        <app-review-card
          class="flex-item"
          *ngIf="isReview1Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer1WorkStatus()"
          title="Reviewer 1"
          [disabled]="isReview1Disabled"
          field="geResultReviewer1"
          [comment]="this.certifyingExam.geResultReviewer1Comment"
          [notes]="this.certifyingExam.geResultReviewer1Notes"
          [accepted]="this.certifyingExam.geResultReviewer1Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isReview2Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer2WorkStatus()"
          title="Reviewer 2"
          [disabled]="isReview2Disabled"
          field="geResultReviewer2"
          [comment]="this.certifyingExam.geResultReviewer2Comment"
          [notes]="this.certifyingExam.geResultReviewer2Notes"
          [accepted]="this.certifyingExam.geResultReviewer2Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewVisible"
          [showHeader]="true"
          title="Final Review"
          [disabled]="isFinalReviewDisabled"
          field="geResult"
          [comment]="this.certifyingExam.geResultComment"
          [accepted]="this.certifyingExam.geResultAccepted"></app-review-card>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="certifyingExam.programmeDirectorLetter?.data || isSubmitLaterSelected('programmeDirectorLetter')">
    <mat-card-header>
      <mat-card-title>Programme Director Letter</mat-card-title>
      <app-required-note [status]="isCredentialRequired('programmeDirectorLetter')"></app-required-note>
    </mat-card-header>

    <mat-card-content>
      <b>Programme Director Letter</b>
      <p>
        A scanned copy of the original signed letters from the Programme Director and any supervisor(s) of other
        institutions (e.g. those providing externship training) involved in the training programme.
      </p>

      <b>Credentials Summary Form</b>
      <p>
        Only oncology Residents are required to upload the credentials summary form as one document with the Programme
        Director letter.
      </p>

      <app-submit-later-alert *ngIf="isSubmitLaterSelected('programmeDirectorLetter')"></app-submit-later-alert>

      <app-review-file-upload
        [files]="convertFileToArray(certifyingExam.programmeDirectorLetter?.data)"></app-review-file-upload>

      <div class="flex-container">
        <app-review-card
          class="flex-item"
          *ngIf="isReview1Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer1WorkStatus()"
          title="Reviewer 1"
          [disabled]="isReview1Disabled"
          field="programmeDirectorLetterReviewer1"
          [comment]="this.certifyingExam.programmeDirectorLetterReviewer1Comment"
          [notes]="this.certifyingExam.programmeDirectorLetterReviewer1Notes"
          [accepted]="this.certifyingExam.programmeDirectorLetterReviewer1Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isReview2Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer2WorkStatus()"
          title="Reviewer 2"
          [disabled]="isReview2Disabled"
          field="programmeDirectorLetterReviewer2"
          [comment]="this.certifyingExam.programmeDirectorLetterReviewer2Comment"
          [notes]="this.certifyingExam.programmeDirectorLetterReviewer2Notes"
          [accepted]="this.certifyingExam.programmeDirectorLetterReviewer2Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewVisible"
          [showHeader]="true"
          title="Final Review"
          [disabled]="isFinalReviewDisabled"
          field="programmeDirectorLetter"
          [comment]="this.certifyingExam.programmeDirectorLetterComment"
          [accepted]="this.certifyingExam.programmeDirectorLetterAccepted"></app-review-card>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card
    *ngIf="certifyingExam.verificationOfTraining?.data?.length || isSubmitLaterSelected('verificationOfTraining')">
    <mat-card-header>
      <mat-card-title>Verification of training in externships</mat-card-title>
      <app-required-note [status]="isCredentialRequired('verificationOfTraining')"></app-required-note>
    </mat-card-header>

    <mat-card-content>
      <p>Please refer to the externship requirements laid out in your specialty Information Brochure.</p>

      <app-submit-later-alert *ngIf="isSubmitLaterSelected('verificationOfTraining')"></app-submit-later-alert>

      <app-review-file-upload [files]="certifyingExam.verificationOfTraining?.data ?? []"></app-review-file-upload>

      <div class="flex-container">
        <app-review-card
          class="flex-item"
          *ngIf="isReview1Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer1WorkStatus()"
          title="Reviewer 1"
          [disabled]="isReview1Disabled"
          field="verificationOfTrainingReviewer1"
          [comment]="this.certifyingExam.verificationOfTrainingReviewer1Comment"
          [notes]="this.certifyingExam.verificationOfTrainingReviewer1Notes"
          [accepted]="this.certifyingExam.verificationOfTrainingReviewer1Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isReview2Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer2WorkStatus()"
          title="Reviewer 2"
          [disabled]="isReview2Disabled"
          field="verificationOfTrainingReviewer2"
          [comment]="this.certifyingExam.verificationOfTrainingReviewer2Comment"
          [notes]="this.certifyingExam.verificationOfTrainingReviewer2Notes"
          [accepted]="this.certifyingExam.verificationOfTrainingReviewer2Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewVisible"
          [showHeader]="true"
          title="Final Review"
          [disabled]="isFinalReviewDisabled"
          field="verificationOfTraining"
          [comment]="this.certifyingExam.verificationOfTrainingComment"
          [accepted]="this.certifyingExam.verificationOfTrainingAccepted"></app-review-card>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card
    *ngIf="certifyingExam.previousCorrespondence?.data?.length || isSubmitLaterSelected('previousCorrespondence')">
    <mat-card-header>
      <mat-card-title>Previous Correspondence</mat-card-title>
      <app-required-note [status]="isCredentialRequired('previousCorrespondence')"></app-required-note>
    </mat-card-header>

    <mat-card-content>
      <p>
        Any previous correspondence pertinent to the individual Resident’s training programme and application (e.g.
        breaks in the programme for ill health or personal reasons, or major changes to the programme since enrolment)
        should be included.
      </p>
      <p>
        Copies of the case summary evaluation letters of the Credential Committee should be submitted as proof of the
        written case summaries.
      </p>

      <app-submit-later-alert *ngIf="isSubmitLaterSelected('previousCorrespondence')"></app-submit-later-alert>

      <app-review-file-upload [files]="certifyingExam.previousCorrespondence?.data ?? []"></app-review-file-upload>

      <div class="flex-container">
        <app-review-card
          class="flex-item"
          *ngIf="isReview1Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer1WorkStatus()"
          title="Reviewer 1"
          [disabled]="isReview1Disabled"
          field="previousCorrespondenceReviewer1"
          [comment]="this.certifyingExam.previousCorrespondenceReviewer1Comment"
          [notes]="this.certifyingExam.previousCorrespondenceReviewer1Notes"
          [accepted]="this.certifyingExam.previousCorrespondenceReviewer1Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isReview2Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer2WorkStatus()"
          title="Reviewer 2"
          [disabled]="isReview2Disabled"
          field="previousCorrespondenceReviewer2"
          [comment]="this.certifyingExam.previousCorrespondenceReviewer2Comment"
          [notes]="this.certifyingExam.previousCorrespondenceReviewer2Notes"
          [accepted]="this.certifyingExam.previousCorrespondenceReviewer2Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewVisible"
          [showHeader]="true"
          title="Final Review"
          [disabled]="isFinalReviewDisabled"
          field="previousCorrespondence"
          [comment]="this.certifyingExam.previousCorrespondenceComment"
          [accepted]="this.certifyingExam.previousCorrespondenceAccepted"></app-review-card>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="certifyingExam.caseLogSummary?.data || isSubmitLaterSelected('caseLogSummary')">
    <mat-card-header>
      <mat-card-title>Signed case log summary</mat-card-title>
      <app-required-note [status]="isCredentialRequired('caseLogSummary')"></app-required-note>
    </mat-card-header>

    <mat-card-content>
      <p>This document must be verified and signed by your Programme Director.</p>

      <app-submit-later-alert *ngIf="isSubmitLaterSelected('caseLogSummary')"></app-submit-later-alert>

      <app-review-file-upload
        [files]="convertFileToArray(certifyingExam.caseLogSummary?.data)"></app-review-file-upload>

      <div class="flex-container">
        <app-review-card
          class="flex-item"
          *ngIf="isReview1Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer1WorkStatus()"
          title="Reviewer 1"
          [disabled]="isReview1Disabled"
          field="caseLogSummaryReviewer1"
          [comment]="this.certifyingExam.caseLogSummaryReviewer1Comment"
          [notes]="this.certifyingExam.caseLogSummaryReviewer1Notes"
          [accepted]="this.certifyingExam.caseLogSummaryReviewer1Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isReview2Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer2WorkStatus()"
          title="Reviewer 2"
          [disabled]="isReview2Disabled"
          field="caseLogSummaryReviewer2"
          [comment]="this.certifyingExam.caseLogSummaryReviewer2Comment"
          [notes]="this.certifyingExam.caseLogSummaryReviewer2Notes"
          [accepted]="this.certifyingExam.caseLogSummaryReviewer2Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewVisible"
          [showHeader]="true"
          title="Final Review"
          [disabled]="isFinalReviewDisabled"
          field="caseLogSummary"
          [comment]="this.certifyingExam.caseLogSummaryComment"
          [accepted]="this.certifyingExam.caseLogSummaryAccepted"></app-review-card>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card
    *ngIf="
      certifyingExam.credentialsSubmissionSummaryForm?.data || isSubmitLaterSelected('credentialsSubmissionSummaryForm')
    ">
    <mat-card-header>
      <mat-card-title>Credentials Submission Summary Form</mat-card-title>
      <app-required-note [status]="isCredentialRequired('credentialsSubmissionSummaryForm')"></app-required-note>
    </mat-card-header>

    <mat-card-content>
      <app-submit-later-alert
        *ngIf="isSubmitLaterSelected('credentialsSubmissionSummaryForm')"></app-submit-later-alert>

      <app-review-file-upload
        [files]="convertFileToArray(certifyingExam.credentialsSubmissionSummaryForm?.data)"></app-review-file-upload>

      <div class="flex-container">
        <app-review-card
          class="flex-item"
          *ngIf="isReview1Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer1WorkStatus()"
          title="Reviewer 1"
          [disabled]="isReview1Disabled"
          field="credentialsSubmissionSummaryFormReviewer1"
          [comment]="this.certifyingExam.credentialsSubmissionSummaryFormReviewer1Comment"
          [notes]="this.certifyingExam.credentialsSubmissionSummaryFormReviewer1Notes"
          [accepted]="this.certifyingExam.credentialsSubmissionSummaryFormReviewer1Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isReview2Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer2WorkStatus()"
          title="Reviewer 2"
          [disabled]="isReview2Disabled"
          field="credentialsSubmissionSummaryFormReviewer2"
          [comment]="this.certifyingExam.credentialsSubmissionSummaryFormReviewer2Comment"
          [notes]="this.certifyingExam.credentialsSubmissionSummaryFormReviewer2Notes"
          [accepted]="this.certifyingExam.credentialsSubmissionSummaryFormReviewer2Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewVisible"
          [showHeader]="true"
          title="Final Review"
          [disabled]="isFinalReviewDisabled"
          field="credentialsSubmissionSummaryForm"
          [comment]="this.certifyingExam.credentialsSubmissionSummaryFormComment"
          [accepted]="this.certifyingExam.credentialsSubmissionSummaryFormAccepted"></app-review-card>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="certifyingExam.presentationLog?.data || isSubmitLaterSelected('presentationLog')">
    <mat-card-header>
      <mat-card-title>Presentation log</mat-card-title>
      <app-required-note [status]="isCredentialRequired('presentationLog')"></app-required-note>
    </mat-card-header>

    <mat-card-content>
      <p>
        This document must be verified and signed by the Programme Director. Residents may be given additional time (up
        to January 15th of the year of the examination) in which to fulfil the requirements for presentations.
      </p>

      <app-submit-later-alert *ngIf="isSubmitLaterSelected('presentationLog')"></app-submit-later-alert>
      <app-review-file-upload
        [files]="convertFileToArray(certifyingExam.presentationLog?.data)"></app-review-file-upload>

      <div class="flex-container">
        <app-review-card
          class="flex-item"
          *ngIf="isReview1Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer1WorkStatus()"
          title="Reviewer 1"
          [disabled]="isReview1Disabled"
          field="presentationLogReviewer1"
          [comment]="this.certifyingExam.presentationLogReviewer1Comment"
          [notes]="this.certifyingExam.presentationLogReviewer1Notes"
          [accepted]="this.certifyingExam.presentationLogReviewer1Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isReview2Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer2WorkStatus()"
          title="Reviewer 2"
          [disabled]="isReview2Disabled"
          field="presentationLogReviewer2"
          [comment]="this.certifyingExam.presentationLogReviewer2Comment"
          [notes]="this.certifyingExam.presentationLogReviewer2Notes"
          [accepted]="this.certifyingExam.presentationLogReviewer2Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewVisible"
          [showHeader]="true"
          title="Final Review"
          [disabled]="isFinalReviewDisabled"
          field="presentationLog"
          [comment]="this.certifyingExam.presentationLogComment"
          [accepted]="this.certifyingExam.presentationLogAccepted"></app-review-card>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card
    *ngIf="
      certifyingExam.caseSummary?.data?.length ||
      certifyingExam.titlePreviousCaseSummary ||
      isSubmitLaterSelected('caseSummary')
    ">
    <mat-card-header>
      <mat-card-title>Case Summary</mat-card-title>
      <app-required-note [status]="isCredentialRequired('caseSummary')"></app-required-note>
    </mat-card-header>

    <mat-card-content>
      <p>
        Internal Medicine and Cardiology Residents must submit one case summary at the time of submitting their
        Credentials (a first case summary must have been submitted with the application for the General Examination).
        The total of case summary is two.
      </p>
      <p>
        Oncology Candidates must submit a <b>maximum of two</b> case summaries at the time of submitting their
        Credentials (at last one case summary must have been submitted with the application for the General
        Examination). The total of case summaries is three.
      </p>
      <app-submit-later-alert *ngIf="isSubmitLaterSelected('caseSummary')"></app-submit-later-alert>

      <app-review-file-upload [files]="certifyingExam.caseSummary?.data ?? []"></app-review-file-upload>
      <app-review-text
        label="Title of previous case summary"
        [text]="certifyingExam.titlePreviousCaseSummary ?? ''"></app-review-text>

      <div class="flex-container">
        <app-review-card
          class="flex-item"
          *ngIf="isReview1Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer1WorkStatus()"
          title="Reviewer 1"
          [disabled]="isReview1Disabled"
          field="caseSummaryReviewer1"
          [comment]="this.certifyingExam.caseSummaryReviewer1Comment"
          [notes]="this.certifyingExam.caseSummaryReviewer1Notes"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isReview2CaseSummaryVisible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer2WorkStatus()"
          title="Reviewer 2"
          [disabled]="isReview2Disabled"
          field="caseSummaryReviewer2"
          [comment]="this.certifyingExam.caseSummaryReviewer2Comment"
          [notes]="this.certifyingExam.caseSummaryReviewer2Notes"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewVisible"
          [showHeader]="true"
          title="Final Review"
          [disabled]="isFinalReviewDisabled"
          field="caseSummary"
          [comment]="this.certifyingExam.caseSummaryComment"></app-review-card>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card
    *ngIf="
      certifyingExam.publications?.data?.length ||
      certifyingExam.publicationLinks ||
      isSubmitLaterSelected('publications')
    ">
    <mat-card-header>
      <mat-card-title>Publications</mat-card-title>
      <app-required-note [status]="isCredentialRequired('publications')"></app-required-note>
    </mat-card-header>

    <mat-card-content>
      <p>
        The types of publications that are acceptable are detailed in Chapter 5.5 of the Information Brochure.
        Candidates may be given additional time (please refer to the current Credentials regulations) in which to fulfil
        the requirements for publications. You can either upload your publications as a file or include the link to your
        publications here below.
      </p>

      <app-submit-later-alert *ngIf="isSubmitLaterSelected('publications')"></app-submit-later-alert>

      <app-review-file-upload [files]="certifyingExam.publications?.data ?? []"></app-review-file-upload>
      <app-review-text label="Publication links" [text]="certifyingExam.publicationLinks ?? ''"></app-review-text>

      <div class="flex-container">
        <app-review-card
          class="flex-item"
          *ngIf="isReview1Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer1WorkStatus()"
          title="Reviewer 1"
          [disabled]="isReview1Disabled"
          field="publicationsReviewer1"
          [comment]="this.certifyingExam.publicationsReviewer1Comment"
          [notes]="this.certifyingExam.publicationsReviewer1Notes"
          [accepted]="this.certifyingExam.publicationsReviewer1Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isReview2Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer2WorkStatus()"
          title="Reviewer 2"
          [disabled]="isReview2Disabled"
          field="publicationsReviewer2"
          [comment]="this.certifyingExam.publicationsReviewer2Comment"
          [notes]="this.certifyingExam.publicationsReviewer2Notes"
          [accepted]="this.certifyingExam.publicationsReviewer2Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewVisible"
          [showHeader]="true"
          title="Final Review"
          [disabled]="isFinalReviewDisabled"
          field="publications"
          [comment]="this.certifyingExam.publicationsComment"
          [accepted]="this.certifyingExam.publicationsAccepted"></app-review-card>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="certifyingExam.caseLog?.data || isSubmitLaterSelected('caseLog')">
    <mat-card-header>
      <mat-card-title>Case Log</mat-card-title>
      <app-required-note [status]="isCredentialRequired('caseLog')"></app-required-note>
    </mat-card-header>

    <mat-card-content>
      <p>
        Residents are required to submit one Excel file. Please refer to the Information Brochure of your Specialty for
        details.
      </p>

      <app-submit-later-alert *ngIf="isSubmitLaterSelected('caseLog')"></app-submit-later-alert>

      <app-review-file-upload [files]="convertFileToArray(certifyingExam.caseLog?.data)"></app-review-file-upload>

      <div class="flex-container">
        <app-review-card
          class="flex-item"
          *ngIf="isReview1Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer1WorkStatus()"
          title="Reviewer 1"
          [disabled]="isReview1Disabled"
          field="caseLogReviewer1"
          [comment]="this.certifyingExam.caseLogReviewer1Comment"
          [notes]="this.certifyingExam.caseLogReviewer1Notes"
          [accepted]="this.certifyingExam.caseLogReviewer1Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isReview2Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer2WorkStatus()"
          title="Reviewer 2"
          [disabled]="isReview2Disabled"
          field="caseLogReviewer2"
          [comment]="this.certifyingExam.caseLogReviewer2Comment"
          [notes]="this.certifyingExam.caseLogReviewer2Notes"
          [accepted]="this.certifyingExam.caseLogReviewer2Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewVisible"
          [showHeader]="true"
          title="Final Review"
          [disabled]="isFinalReviewDisabled"
          field="caseLog"
          [comment]="this.certifyingExam.caseLogComment"
          [accepted]="this.certifyingExam.caseLogAccepted"></app-review-card>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card
    *ngIf="certifyingExam.multipleChoiceQuestionnaire?.data || isSubmitLaterSelected('multipleChoiceQuestionnaire')">
    <mat-card-header>
      <mat-card-title>Multiple Choice Questions</mat-card-title>
      <app-required-note [status]="isCredentialRequired('multipleChoiceQuestionnaire')"></app-required-note>
    </mat-card-header>

    <mat-card-content>
      <p>
        Four multiple choice (single best answer) questions must be provided with the application by Cardiology and
        Internal Medicine Residents. These questions MUST be based on information contained within the reading list.
      </p>

      <app-submit-later-alert *ngIf="isSubmitLaterSelected('multipleChoiceQuestionnaire')"></app-submit-later-alert>

      <app-review-file-upload
        [files]="convertFileToArray(certifyingExam.multipleChoiceQuestionnaire?.data)"></app-review-file-upload>

      <div class="flex-container">
        <app-review-card
          class="flex-item"
          *ngIf="isReview1Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer1WorkStatus()"
          title="Reviewer 1"
          [disabled]="isReview1Disabled"
          field="multipleChoiceQuestionnaireReviewer1"
          [comment]="this.certifyingExam.multipleChoiceQuestionnaireReviewer1Comment"
          [notes]="this.certifyingExam.multipleChoiceQuestionnaireReviewer1Notes"
          [accepted]="this.certifyingExam.multipleChoiceQuestionnaireReviewer1Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isReview2Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer2WorkStatus()"
          title="Reviewer 2"
          [disabled]="isReview2Disabled"
          field="multipleChoiceQuestionnaireReviewer2"
          [comment]="this.certifyingExam.multipleChoiceQuestionnaireReviewer2Comment"
          [notes]="this.certifyingExam.multipleChoiceQuestionnaireReviewer2Notes"
          [accepted]="this.certifyingExam.multipleChoiceQuestionnaireReviewer2Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewVisible"
          [showHeader]="true"
          title="Final Review"
          [disabled]="isFinalReviewDisabled"
          field="multipleChoiceQuestionnaire"
          [comment]="this.certifyingExam.multipleChoiceQuestionnaireComment"
          [accepted]="this.certifyingExam.multipleChoiceQuestionnaireAccepted"></app-review-card>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="certifyingExam.procedureLog?.data || isSubmitLaterSelected('procedureLog')">
    <mat-card-header>
      <mat-card-title>Procedure Log</mat-card-title>
      <app-required-note [status]="isCredentialRequired('procedureLog')"></app-required-note>
    </mat-card-header>

    <mat-card-content>
      <p>Residents are required to submit their Procedure log signed by their Programme Director.</p>

      <app-submit-later-alert *ngIf="isSubmitLaterSelected('procedureLog')"></app-submit-later-alert>

      <app-review-file-upload [files]="convertFileToArray(certifyingExam.procedureLog?.data)"></app-review-file-upload>

      <div class="flex-container">
        <app-review-card
          class="flex-item"
          *ngIf="isReview1Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer1WorkStatus()"
          title="Reviewer 1"
          [disabled]="isReview1Disabled"
          field="procedureLogReviewer1"
          [comment]="this.certifyingExam.procedureLogReviewer1Comment"
          [notes]="this.certifyingExam.procedureLogReviewer1Notes"
          [accepted]="this.certifyingExam.procedureLogReviewer1Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isReview2Visible"
          [showHeader]="isFinalReviewer"
          [workStatus]="certifyingExam.getReviewer2WorkStatus()"
          title="Reviewer 2"
          [disabled]="isReview2Disabled"
          field="procedureLogReviewer2"
          [comment]="this.certifyingExam.procedureLogReviewer2Comment"
          [notes]="this.certifyingExam.procedureLogReviewer2Notes"
          [accepted]="this.certifyingExam.procedureLogReviewer2Accepted"></app-review-card>

        <app-review-card
          class="flex-item"
          *ngIf="isFinalReviewVisible"
          [showHeader]="true"
          title="Final Review"
          [disabled]="isFinalReviewDisabled"
          field="procedureLog"
          [comment]="this.certifyingExam.procedureLogComment"
          [accepted]="this.certifyingExam.procedureLogAccepted"></app-review-card>
      </div>
    </mat-card-content>
  </mat-card>

  <!-- BUTTONS AND RESULT -->

  <mat-card>
    <div *ngIf="isFinalReviewVisible">
      <app-review-file-upload
        [files]="
          (certifyingExam.applicationResultLetters?.data ?? []).concat(
            convertFileToArray(certifyingExam.caseSummaryConclusionLetter?.data)
          )
        "></app-review-file-upload>

      <ng-container *ngIf="isViewer">
        <app-review-text
          label="Application Result"
          [text]="this.certifyingExam.applicationResult ?? ''"></app-review-text>
        <app-review-large-text
          *ngIf="this.certifyingExam.applicationResultComment?.trim()"
          label="Application Result Comment"
          [text]="this.certifyingExam.applicationResultComment ?? ''"></app-review-large-text>
        <app-review-text
          label="Full Credentials Fee Next Application"
          [text]="this.certifyingExam.fullCredentialsFeeNextApplication ? 'Yes' : 'No'"></app-review-text>
      </ng-container>

      <ng-container *ngIf="!isViewer">
        <app-certifying-exam-application-result-form
          [disabled]="isFinalReviewDisabled"
          [applicationResult]="this.certifyingExam.applicationResult"
          [applicationResultComment]="this.certifyingExam.applicationResultComment"
          [fullCredentialsFeeNextApplication]="this.certifyingExam.fullCredentialsFeeNextApplication">
        </app-certifying-exam-application-result-form>
      </ng-container>
    </div>

    <ng-container *ngIf="!isViewer">
      <button
        mat-raised-button
        (click)="saveReview()"
        [disabled]="
          loading ||
          certifyingExam.isReviewCompleted() ||
          (isReviewer1 && isReview1Disabled) ||
          (isReviewer2 && isReview2Disabled) ||
          (isFinalReviewer && isFinalReviewDisabled)
        ">
        Save <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
      </button>

      <button
        mat-raised-button
        (click)="saveReview(true)"
        [disabled]="
          loading ||
          !isReviewValid ||
          certifyingExam.isReviewCompleted() ||
          (isReviewer1 && isReview1Disabled) ||
          (isReviewer2 && isReview2Disabled) ||
          (isFinalReviewer && isFinalReviewDisabled)
        ">
        Submit <mat-icon *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
      </button>
    </ng-container>
  </mat-card>
</app-grid>
