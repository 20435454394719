import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';

import { CertifyingExam } from '../models/certifying-exam.model';
import { CertifyingExamForReviewByIdResolver } from '../resolvers/exam-application/certifying-exam-for-review-by-id.resolver';
import { MemberResolverService } from '../resolvers/member.resolver.service';
import { CertifyingExamService } from '../services/certifying-exam.service';
import { CommitteeService } from '../services/committee.service';
import { ReviewType } from '../utils/review';

@Injectable({
  providedIn: 'root',
})
export class CertifyingExamReviewGuard implements CanActivate {
  constructor(
    private router: Router,
    private committeeService: CommitteeService,
    private memberResolver: MemberResolverService,
    private certifyingExamService: CertifyingExamService,
    private certifyingExamResolver: CertifyingExamForReviewByIdResolver
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!Object.values(ReviewType).includes(route.paramMap.get('type') as any)) {
      this.router.navigate(['/committee-work/credentials-review']);
      return false;
    }

    const reviewType = route.paramMap.get('type') as ReviewType;

    return this.memberResolver.resolve(route, state).pipe(
      switchMap(({ member: memberEntity }) => {
        const memberId = memberEntity?.id ?? undefined;
        if (memberId === undefined) {
          return of(false);
        }
        return forkJoin([
          this.committeeService.getCredentialsCommitteesAsChair(memberId),
          this.certifyingExamResolver.resolve(route, state),
          this.certifyingExamService.getCertifyingExamsForReviewByReviewer(memberId),
        ]).pipe(
          map(([committeesAsChairRes, certifyingExamEntity, allCertifyingExamEntitiesForReview]) => {
            const committeesAsChair = committeesAsChairRes?.data?.comitees?.data ?? [];
            const specialitiesAsChair = committeesAsChair
              .map((committee) => committee.attributes?.speciality?.Name)
              .filter((s) => !!s)
              .map((s) => s!.replace('_', ' '));

            const allCertifyingExamsForReview = allCertifyingExamEntitiesForReview.map((cee) => cee.attributes!);

            let canActivate = false;
            if (!!certifyingExamEntity.attributes) {
              const ce = CertifyingExam.from(certifyingExamEntity.attributes);
              canActivate =
                (reviewType == ReviewType.First && ce.isReviewer1(memberId)) ||
                (reviewType == ReviewType.Second && ce.isReviewer2(memberId)) ||
                ((reviewType == ReviewType.Final || reviewType == ReviewType.View) &&
                  specialitiesAsChair.includes(ce?.speciality?.Name?.replace('_', ' ') ?? '')) ||
                (reviewType == ReviewType.View &&
                  allCertifyingExamsForReview.some(
                    (otherCE) =>
                      otherCE.speciality?.Name === ce.speciality?.Name &&
                      otherCE.examYear > ce.examYear &&
                      otherCE.resident?.data?.id == ce.resident?.data?.id
                  ));
            }

            if (!canActivate) {
              this.router.navigate(['/committee-work/credentials-review']);
            }

            return canActivate;
          })
        );
      })
    );
  }
}
