import { Defined } from '../utils/utils';

export abstract class Basic<T> {
  protected constructor(data: T) {
    Object.assign(this, data);
  }

  get<K extends keyof T>(key: K) {
    const value = (this as unknown as T)[key];
    if (value === undefined) {
      throw new Error(`${key.toString()} is not defined`);
    }
    return value as Defined<T[K]>;
  }
}
