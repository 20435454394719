import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS, MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MarkdownModule } from 'ngx-markdown';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { AppComponent } from './app.component';
import { AlertComponent } from './components/alert/alert.component';
import { AutoOpenMenuComponent } from './components/auto-open-menu/auto-open-menu.component';
import { DiplomateReevaluationApplicationFormComponent } from './components/diplomate-reevaluation/diplomate-reevaluation-application-form/diplomate-reevaluation-application-form.component';
import { DiplomateReevaluationDataVerificationFormComponent } from './components/diplomate-reevaluation/diplomate-reevaluation-data-verification-form/diplomate-reevaluation-data-verification-form.component';
import { DiplomateReevaluationResultFormComponent } from './components/diplomate-reevaluation/diplomate-reevaluation-result-form/diplomate-reevaluation-result-form.component';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { CertifyingExamApplicationDetailsComponent } from './components/exam-application/certifying-exam-application-details/certifying-exam-application-details.component';
import { CertifyingExamApplicationFormComponent } from './components/exam-application/certifying-exam-application-form/certifying-exam-application-form.component';
import { CertifyingExamApplicationResultFormComponent } from './components/exam-application/certifying-exam-application-result-form/certifying-exam-application-result-form.component';
import { CertifyingExamDataVerificationFormComponent } from './components/exam-application/certifying-exam-data-verification-form/certifying-exam-data-verification-form.component';
import { CredentialsSubmissionFormComponent } from './components/exam-application/credentials-submission-form/credentials-submission-form.component';
import { GeneralExamApplicationDetailsComponent } from './components/exam-application/general-exam-application-details/general-exam-application-details.component';
import { GeneralExamApplicationFormComponent } from './components/exam-application/general-exam-application-form/general-exam-application-form.component';
import { GeneralExamDataVerificationFormComponent } from './components/exam-application/general-exam-data-verification-form/general-exam-data-verification-form.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ContentComponent } from './components/layout/content/content.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { GridComponent } from './components/layout/grid/grid.component';
import { GridRowComponent } from './components/layout/grid-row/grid-row.component';
import { LayoutComponent } from './components/layout/layout.component';
import { MemberDialogComponent } from './components/member-dialog/member-dialog.component';
import { MemberFormComponent } from './components/member-form/member-form.component';
import { NewUserInfoComponent } from './components/new-user-info/new-user-info.component';
import { NewsCardComponent } from './components/news-card/news-card.component';
import { PaymentListComponent } from './components/payment-list/payment-list.component';
import { ResidentDetailsComponent } from './components/resident-details/resident-details.component';
import { RequiredNoteComponent } from './components/review/required-note/required-note.component';
import { ReviewCardComponent } from './components/review/review-card/review-card.component';
import { ReviewFileUploadComponent } from './components/review/review-file-upload/review-file-upload.component';
import { ReviewFormComponent } from './components/review/review-form/review-form.component';
import { ReviewLargeTextComponent } from './components/review/review-large-text/review-large-text.component';
import { ReviewTextComponent } from './components/review/review-text/review-text.component';
import { SubmitLaterAlertComponent } from './components/review/submit-later-alert/submit-later-alert.component';
import { WorkStatusIconComponent } from './components/review/work-status-icon/work-status-icon.component';
import { SimpleTableComponent } from './components/simple-table/simple-table.component';
import { SpacerComponent } from './components/spacer/spacer.component';
import { GraphQLModule } from './graphql.module';
import { AuthCallbackComponent } from './pages/auth-callback/auth-callback.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { CmsPageComponent } from './pages/cms-page/cms-page.component';
import { DiplomateReevaluationReviewComponent } from './pages/diplomate-reevaluation/diplomate-reevaluation-review/diplomate-reevaluation-review.component';
import { DiplomateReevaluationReviewOverviewComponent } from './pages/diplomate-reevaluation/diplomate-reevaluation-review-overview/diplomate-reevaluation-review-overview.component';
import { DiplomateReevaluationSubmissionComponent } from './pages/diplomate-reevaluation/diplomate-reevaluation-submission/diplomate-reevaluation-submission.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { CertifyingExamApplicationSubmissionComponent } from './pages/exam-application/certifying-exam-application-submission/certifying-exam-application-submission.component';
import { CertifyingExamReviewComponent } from './pages/exam-application/certifying-exam-review/certifying-exam-review.component';
import { ExamReviewOverviewComponent } from './pages/exam-application/exam-review-overview/exam-review-overview.component';
import { GeneralExamApplicationSubmissionComponent } from './pages/exam-application/general-exam-application-submission/general-exam-application-submission.component';
import { GeneralExamReviewComponent } from './pages/exam-application/general-exam-review/general-exam-review.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { EscapeHtmlPipe } from './pipes/keep-html.pipe';
import { RoutingModule } from './routing.module';

@NgModule({
  declarations: [
    AppComponent,
    GridComponent,
    GridRowComponent,
    HomeComponent,
    ProfileComponent,
    SpacerComponent,
    MemberDialogComponent,
    PaymentsComponent,
    CheckoutComponent,
    AuthCallbackComponent,
    PaymentListComponent,
    MemberFormComponent,
    CmsPageComponent,
    ContentComponent,
    EscapeHtmlPipe,
    NewUserInfoComponent,
    CertifyingExamApplicationSubmissionComponent,
    CredentialsSubmissionFormComponent,
    FileUploadComponent,
    CertifyingExamApplicationFormComponent,
    CertifyingExamDataVerificationFormComponent,
    GeneralExamApplicationSubmissionComponent,
    GeneralExamApplicationFormComponent,
    GeneralExamDataVerificationFormComponent,
    FooterComponent,
    LayoutComponent,
    CertifyingExamReviewComponent,
    ReviewFormComponent,
    GeneralExamReviewComponent,
    ReviewFileUploadComponent,
    ReviewTextComponent,
    WorkStatusIconComponent,
    ReviewCardComponent,
    DiplomateReevaluationSubmissionComponent,
    DiplomateReevaluationReviewComponent,
    DiplomateReevaluationApplicationFormComponent,
    DiplomateReevaluationDataVerificationFormComponent,
    ExamReviewOverviewComponent,
    DiplomateReevaluationReviewOverviewComponent,
    CertifyingExamApplicationResultFormComponent,
    DiplomateReevaluationResultFormComponent,
    SimpleTableComponent,
    ReviewLargeTextComponent,
    ResidentDetailsComponent,
    AlertComponent,
    CertifyingExamApplicationDetailsComponent,
    GeneralExamApplicationDetailsComponent,
    SubmitLaterAlertComponent,
    RequiredNoteComponent,
    DocumentsComponent,
    DocumentListComponent,
    AutoOpenMenuComponent,
    NewsCardComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    GraphQLModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatDatepickerModule,
    HttpClientModule,
    MarkdownModule.forRoot(),
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatMenuModule,
    MatInputModule,
    MatButtonToggleModule,
    MatIconModule,
    MatBadgeModule,
    MatListModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatGridListModule,
    MatToolbarModule,
    MatLuxonDateModule,
    MatSidenavModule,
    RoutingModule,
    OverlayModule,
  ],
  providers: [
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: true,
      },
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'y-LL-dd',
        },
        display: {
          dateInput: 'y-LL-dd',
          monthYearLabel: 'y-LL',
          dateA11yLabel: 'y-LL-dd',
          monthYearA11yLabel: 'y-LL',
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
