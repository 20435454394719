export type Defined<T> = T extends undefined ? never : T;

export function argmax<T>(elements: T[], elementToNumber: (element: T) => number) {
  if (elements.length == 0) {
    throw new Error('elments size must 1 or greater');
  }
  return elements.reduce((maxElement, element) =>
    elementToNumber(element) > elementToNumber(maxElement) ? element : maxElement
  );
}

export function getDateString(value: string | number | Date) {
  return new Date(value).toISOString().split('T')[0];
}

export function getDate(value: string | number | Date | undefined | null) {
  return value !== undefined && value !== null ? new Date(value) : undefined;
}

export function enumValueToString(value: string) {
  return value.replaceAll('_', ' ');
}

export function unique<T, K>(array: T[], mapper?: (arg: T) => K) {
  const set = new Set();
  return array.reduce((result, element) => {
    const key = mapper ? mapper(element) : element;
    if (!set.has(key)) {
      set.add(key);
      result.push(element);
    }
    return result;
  }, [] as T[]);
}

export function capitalize(word: string) {
  if (!word) return word;
  return word[0].toUpperCase() + word.substr(1).toLowerCase();
}
