import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsitemEntity } from 'models/types';
import { MemberService } from 'src/app/services/member.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  newsItems: NewsitemEntity[] = [];

  get hasRole() {
    return this.memberService.hasRole;
  }

  constructor(private activatedRoute: ActivatedRoute, private memberService: MemberService) {
    this.newsItems = this.activatedRoute.snapshot.data['news'] as NewsitemEntity[];
  }
}
